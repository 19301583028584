@import "./global/theme";
@import "./global/bootstrap";
@import "./global/com";
@import "./global/btn";
@import "./global/tradeAndDashboard";

//谷歌人机校验
.grecaptcha-badge {
  display: none !important;
}

.particle-wallet-entry-container {
  display: none;
}

// coinbase wallet
.-cbwsdk-connect-item:nth-of-type(2) {
  display: none !important;
}

// tailwindcss
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: var(--trade-body-bg);
    --foreground: 0 0% 100%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 51 99% 50%;
    --primary-foreground: 0 0% 100%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

.deposit-nav {
  border-bottom: none;
  padding: 0;
}
.d-x-nav-bar .x-nav-item.x-nav-item-active {
  border-bottom: 2px solid #ffce4b;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background: #1f1f1f;
  max-width: 250px;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow:
    0px 17px 21.3px 0px rgba(0, 0, 0, 0.35),
    0px 7px 8.9px 0px rgba(0, 0, 0, 0.4);
}
