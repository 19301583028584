@import "font";
html,
body {
  background: var(--trade-body-bg);
  -webkit-tap-highlight-color: transparent;
}

* {
  font-family:
    "All Round Gothic",
    "Poppins",
    -apple-system,
    "system-ui",
    Roboto,
    Arial,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
tr,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
br,
img,
table,
input,
form,
a,
p,
textarea {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
}

a {
  display: block;
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #ffce4b;
}

img {
  display: block;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

span {
  display: inline-block;
}
