//绿色 买色按钮
.com-btn-color-long {
  background-color: var(--trade-color-long);
  color: var(--trade-font-light);
  transition: all 60ms linear;

  &.com-btn-disable {
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    background-color: var(--trade-color-long-hover);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }
}

//红色 卖色按钮
.com-btn-color-short {
  background-color: var(--trade-color-short);
  color: var(--trade-font-light);
  transition: all 120ms linear;

  &.com-btn-disable {
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    background-color: var(--trade-color-short-hover);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }
}

//黄色 操作色按钮
.com-btn-color-operate {
  background-color: var(--trade-color-operate);
  color: var(--trade-font-black);
  transition: all 120ms linear;

  &.com-btn-disable {
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    background-color: var(--trade-color-operate-hover);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }

  .x-button-loading {
    fill: var(--trade-font-black);
  }
}

//普通灰色按钮
.com-btn-color-normal {
  background-color: var(--trade-color-normal);
  color: var(--trade-font-light);
  transition: all 120ms linear;

  &.com-btn-disable {
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    background-color: var(--trade-color-normal-hover);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }
}

//线框按钮
.com-btn-color-line {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--trade-font-light);
  transition: all 120ms linear;

  &.com-btn-disable {
    border: none;
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    border: 1px solid var(--trade-color-operate-hover);
    color: var(--trade-color-operate-hover);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }
}

//文字按钮
.com-btn-color-txt {
  background-color: transparent;
  color: var(--trade-color-operate-hover);
  transition: all 120ms linear;
  transform-origin: center;

  &.com-btn-disable {
    background-color: var(--trade-color-normal-disable);
    color: var(--trade-font-disable);
    cursor: not-allowed;
  }

  &:not(.com-btn-disable):hover {
    color: var(--trade-color-operate);
  }

  &:not(.com-btn-disable):active {
    transform: scale(0.98);
  }

  .x-button-loading {
    fill: var(--trade-color-operate);
  }
}

.show-setting-info-btn {
  padding: 2px 12px;
  border-radius: 4px;
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
}

.NButton-size-small {
  padding: 8px 16px;
}
.NButton-size-middle {
  padding: 12px 20px;
}
.NButton-size-large {
  padding: 16px 24px;
}

.NButton-type-default {
  padding: 16px 24px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  span {
    background: var(--Sec-btn-gradient, linear-gradient(280deg, #ffce4b 51.91%, #f452d6 106.71%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
}

.NButton-type-no-line {
  padding: 16px 24px;
  cursor: pointer;
  span {
    background: var(--Sec-btn-gradient, linear-gradient(280deg, #ffce4b 51.91%, #f452d6 106.71%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
}

.NButton-type-main {
  padding: 16px 24px;
  border-radius: 4px;
  color: #222222;
  cursor: pointer;
  background: linear-gradient(90deg, #ffce4b 0.01%, #f452d6 99.99%);
  span {
    font-size: 16px;
    line-height: 20px;
  }
}
