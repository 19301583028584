#Oauth2 {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#Oauth2 iframe {
  border: none;
  border-radius: 20px;
  box-shadow:
    0px 30px 56px 0px rgba(0, 0, 0, 0.35),
    0px 4px 12px 0px rgba(0, 0, 0, 0.65);
  width: 582px;
  height: 602px;
}
