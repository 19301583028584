$ctrWidth: 372px;

#xToastArea-top {
  top: 32px;
}

#xToastArea-bottom,
.toast-area-bottom {
  bottom: 60px;
  display: flex;
  flex-direction: column-reverse;
}
#xToastArea-top,
#xToastArea-bottom,
.toast-area-bottom {
  position: fixed;
  z-index: 9999;
  // z-index: 11;
  right: 0;

  transition: all 100ms linear;

  .x-toast-outer {
    width: 100%;
    padding: 16px 16px 0 0;
    overflow: hidden;

    &.x-toast-hide {
      animation: hideToastOuter 120ms linear forwards;
    }
  }

  .x-toast-ctr {
    position: relative;
    width: $ctrWidth;
    padding: 16px;
    background: var(--trade-dialog-bg);
    border: 1px solid var(--trade-dialog-border-color);
    box-shadow:
      0 8px 12px rgba(0, 0, 0, 0.2),
      0 12px 48px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    transform: translateX(100%);

    &.toast-ctr-show {
      animation: showToast ease-in-out 120ms forwards;
    }
  }

  .x-toast-info-ctr {
    display: flex;
  }

  .x-toast-icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    fill: var(--trade-font-light);

    &.Toast_info-icon {
      fill: #ffc412;
    }
    &.Toast_fail-icon {
      fill: var(--trade-color-short-constant);
    }
    &.Toast_loading-icon {
      fill: var(--trade-color-long-constant);
    }
    &.Toast_success-icon {
      fill: var(--trade-color-long-constant);
    }
  }
  .x-toast-icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    fill: rgba(255, 255, 255, 0.45);
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .x-toast-msg {
    margin-left: 10px;
    font-size: 14px;
    color: var(--trade-toast-color);
    text-align: justify;

    .x-toast-title {
      font-weight: 500;
      font-size: 16px;
      color: #fff;
    }

    .x-toast-txt {
      margin-top: 8px;
      line-height: 18px;
      white-space: break-spaces;
      word-break: break-word;
      text-align: left;
      font-size: 14px;
      color: var(--trade-font-grey);
    }
  }

  .x-toast-operate-ctr {
    display: flex;
    padding-top: 8px;

    .x-toast-btn-ctr {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .Toast_loading-icon {
    animation: rotate 0.6s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes showToast {
    from {
      transform: matrix(1, 0, 0, 1, $ctrWidth, 0);
    }
    to {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  @keyframes hideToastOuter {
    to {
      height: 0;
      padding-top: 0;
    }
  }
}

.toast-async-dialog {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: showMask 100ms linear forwards;

  .toast-dialog-ctr {
    width: 400px;
    padding-bottom: 24px;
    border-radius: 8px;
    background-color: var(--trade-dialog-bg);
    color: var(--trade-font-light);
  }

  .toast-dialog-title-ctr {
    display: flex;
    height: 50px;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    font-size: 16px;
    font-weight: 500;
    user-select: none;
  }

  .toast-dialog-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
  }

  .toast-dialog-close {
    width: 32px;
    height: 32px;
    fill: var(--trade-font-light);
    opacity: 0.8;
    padding: 6px;

    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 1;
      background-color: var(--trade-color-normal);
      border-radius: 8px;
    }
  }

  .toast-dialog-content {
    padding: 0 24px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .toast-dialog-tips {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .hide-next-time {
    margin-bottom: 16px;
  }

  .toast-dialog-operate-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .toast-dialog-operate-btn {
    flex: 1;
    height: 44px;
    padding: 0 6px;
    border-radius: 40px;
    font-weight: 700;

    &:nth-of-type(2) {
      margin-left: 16px;
    }
  }

  .price-tips {
    margin-bottom: 8px;
  }

  .tips-bold {
    margin: 0 4px;
    font-weight: 700;
  }
}
