:root {
  --trade-body-width: 1420px;

  --trade-dashbody-width: 1024px;

  --trade-body-bg: #0f0f0f;
  --trade-module-bg: #141414;
  --trade-tips-bg: #333333;
  --trade-toast-bg: #c4c4c4;
  --trade-dialog-mask: rgba(0, 0, 0, 0.4);
  --trade-dialog-bg: #1f1f1f;
  --trade-dialog-border-color: #45464b;

  --BG-Lighten: #1f1f1f;

  --trade-btn-bg: #1f1f1f;
  //--trade-popover-bg: #d8d9dd;
  --trade-popover-bg: #3d3d3d;

  --trade-color-operate: #ffce4b;
  --trade-color-operate-hover: #b3890d;
  --trade-color-operate-opacity: rgba(255, 196, 18, 0.24);

  --trade-color-normal: #45464b;
  --trade-color-normal-hover: #333439;
  --trade-color-normal-disable: #2a2a30;

  --trade-font-light: #ffffff;
  --trade-font-grey: rgba(174, 174, 176, 0.55);
  --trade-font-black: #121212;
  --trade-font-disable: #5d5d61;

  --trade-line-grey: #333439;

  --trade-toast-color: #aeaeb0;

  --dashboard-title-color: #fff;
  // --dashboard-title-color: #dcdcdc;

  --module-title-bg: #222222;

  //新版颜色
  --trade-v1-long: #00ffd1;
  --trade-v1-short: #f15d54;

  --trade-classical-long: #00ffd1;
  --trade-classical-short: #f15d54;
}

:root.classical-normal {
  --trade-color-long: #00ffd1;
  --trade-color-long-opacity: rgba(15, 152, 79, 0.1);
  --trade-color-long-hover: #0b6a37;

  --trade-color-short: #f15d54;
  --trade-color-short-opacity: rgba(241, 93, 84, 0.08);
  --trade-color-short-hover: #9c2d2d;

  --trade-color-long-constant: #00ffd1;
  --trade-color-long-opacity-constant: rgba(15, 152, 79, 0.1);
  --trade-color-short-constant: #f15d54;
  --trade-color-short-opacity-constant: rgba(241, 93, 84, 0.08);

  --trade-color-long-stop-start: rgb(33, 39, 44);
  --trade-color-short-stop-start: rgb(49, 26, 25);
}

:root.classical-reverse {
  --trade-color-long: #f15d54;
  --trade-color-long-opacity: rgba(241, 93, 84, 0.08);
  --trade-color-long-hover: #9c2d2d;

  --trade-color-short: #00ffd1;
  --trade-color-short-opacity: rgba(15, 152, 79, 0.1);
  --trade-color-short-hover: #0b6a37;

  --trade-color-long-constant: #00ffd1;
  --trade-color-long-opacity-constant: rgba(15, 152, 79, 0.1);
  --trade-color-short-constant: #f15d54;
  --trade-color-short-opacity-constant: rgba(241, 93, 84, 0.08);

  --trade-color-long-stop-start: rgb(49, 26, 25);
  --trade-color-short-stop-start: rgb(33, 39, 44);
}

:root.v1-normal {
  --trade-color-long: #00ffd1;
  --trade-color-long-opacity: rgba(0, 255, 209, 0.2);
  --trade-color-long-hover: #559800;

  --trade-color-short: #f15d54;
  --trade-color-short-opacity: rgba(241, 93, 84, 0.08);
  --trade-color-short-hover: #b30055;

  --trade-color-long-constant: #00ffd1;
  --trade-color-long-opacity-constant: rgba(0, 255, 209, 0.2);
  --trade-color-short-constant: #f15d54;
  --trade-color-short-opacity-constant: rgba(241, 93, 84, 0.08);

  --trade-color-long-stop-start: rgba(35, 47, 21);
  --trade-color-short-stop-start: rgba(54, 22, 34);
}

:root.v1-reverse {
  --trade-color-long: #f15d54;
  --trade-color-long-opacity: rgba(241, 93, 84, 0.08);
  --trade-color-long-hover: #b30055;

  --trade-color-short: #00ffd1;
  --trade-color-short-opacity: rgba(0, 255, 209, 0.2);
  --trade-color-short-hover: #559800;

  --trade-color-long-constant: #00ffd1;
  --trade-color-long-opacity-constant: rgba(0, 255, 209, 0.2);
  --trade-color-short-constant: #f15d54;
  --trade-color-short-opacity-constant: rgba(241, 93, 84, 0.08);

  --trade-color-long-stop-start: rgba(54, 22, 34);
  --trade-color-short-stop-start: rgba(35, 47, 21);
}
