@keyframes xLoading {
  from {
    transform: rotate3d(0, 0, 1, 0);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.x-loading {
  fill: var(--trade-color-operate);
  animation: xLoading 400ms linear infinite;
  width: 24px;
  height: 24px;
}
