.x-toast-msg {
  width: 100%;
}
.confirmationToast {
  .progress-bar {
    margin: 16px 0;
    background-color: rgba(255, 255, 255, 0.5442) !important;
    border-radius: 99px;
    span {
      background: #ffc412;
    }
  }

  .f-12 {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .f-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .default {
    color: rgba(255, 255, 255, 0.65);
  }
  .active {
    color: #fff;
  }
  .justify-content-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .mode-icon {
    color: #7f8083;
    fill: #7f8083;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
