.com-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// 通用
.com-loading-with-animation {
  position: relative;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;

    background: radial-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: loadingCircle 800ms linear infinite;
  }
}

@keyframes loadingCircle {
  from,
  to {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1);
  }
}

.com-tooltips-icon {
  margin-left: 2px;
  width: 12px;
  height: 12px;
  fill: var(--trade-font-grey);

  &:hover {
    fill: var(--trade-font-light);
  }
}

.com-top-16 {
  margin-top: 16px;
}

.com-left-12 {
  margin-left: 12px;
}

.com-flex-line {
  display: flex;
  align-items: center;

  &.align-center {
    justify-content: center;
  }

  &.align-between {
    justify-content: space-between;
  }
}

.com-trigger-type-txt {
  margin-left: 4px;
  color: var(--trade-font-grey);
}

.com-text-align-center {
  text-align: center;
}

.com-gap-margin {
  margin: 0 4px;
}

.com-tips-word {
  cursor: help;
  border-bottom: 1px dotted;
}

.com-connect-icon {
  width: 52px;
  height: 52px;
  fill: var(--trade-font-grey);
}

.customize-scroll-bar {
  &::-webkit-scrollbar {
    /* 隐藏默认的滚动条 */
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    /* 设置垂直滚动条宽度 */
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.12);
  }
}
