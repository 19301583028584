// 100 - Thin.
// 200 - Extra Light (Ultra Light)
// 300 - Light.
// 400 - Regular (Normal、Book、Roman)
// 500 - Medium.
// 600 - Semi Bold (Demi Bold)
// 700 - Bold.

@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/XLig.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/XLig-Oblique.otf") format("opentype");
  font-weight: 300;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Book-Oblique.otf") format("opentype");
  font-weight: 400;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Medium-Oblique.otf") format("opentype");
  font-weight: 500;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Demi.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Demi-Oblique.otf") format("opentype");
  font-weight: 600;
  font-style: oblique;
  font-display: swap;
}

@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("../../font/AllRoundGothic/Bold-Oblique.otf") format("opentype");
  font-weight: 700;
  font-style: oblique;
  font-display: swap;
}
