.x-checkbox-ctr {
  display: inline-block;
  font-size: 12px;

  .x-checkbox-input {
    display: none;
  }

  &.checkbox-sell {
    color: var(--trade-color-short);
  }
}

.checkbox-buy {
  .is-checked {
    color: var(--trade-color-long);
  }
}

.checkbox-sell {
  .is-checked {
    color: var(--trade-color-short);
  }
}

.checkbox-pure {
  .is-checked {
    color: var(--trade-font-light);
  }
}

.x-checkbox-label {
  display: flex;
  align-items: center;
  color: var(--trade-font-grey);
  user-select: none;

  &.x-disable {
    cursor: not-allowed;
    .x-forge-box {
      background-color: var(--trade-font-grey);
    }
  }

  &:not(.x-disable) {
    cursor: pointer;

    &.is-checked {
      .x-forge-box {
        background: var(--trade-color-operate);
        border: none;

        &::before,
        &::after {
          opacity: 1;
        }
      }
    }

    &:not(.is-checked):hover {
      color: var(--trade-font-light);

      .x-checkbox-label-txt {
        opacity: 0.7;
      }
    }
  }

  .x-checkbox-label-txt {
    line-height: 1;
  }
}

.x-forge-box {
  position: relative;
  height: 14px;
  width: 14px;
  margin-right: 4px;
  border-radius: 2px;
  background: transparent;
  border: 1px solid var(--trade-font-grey);
  transition: all 120ms linear;

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
    background: var(--trade-body-bg);
    opacity: 0;
  }

  &::before {
    top: 2px;
    left: -4px;
    height: 1px;
    width: 4px;
    transform: rotate(45deg);
  }

  &::after {
    top: 1px;
    left: 2px;
    height: 1px;
    width: 6px;
    transform: rotate(-45deg);
  }

  &:active {
    &::before,
    &::after {
      opacity: 0.6;
    }
  }
}
