.close-pnl-tips {
  font-size: 12px;

  .close-pnl-tips-title {
    line-height: 140%;
    font-weight: 700;
    font-size: 14px;
  }

  .close-pnl-label {
    color: var(--trade-font-grey);
  }

  .formula-tips-ctr {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--trade-toast-color);
  }
}
