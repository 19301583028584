.x-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 14px;

  &.com-btn-disable {
    pointer-events: none;
  }
}

.x-button,
.x-button-txt {
  cursor: pointer;
}

.x-button-txt-ctr {
  position: relative;
  display: flex;
  flex-direction: column;

  &.x-button-txt-hide {
    opacity: 0;
  }
}

.x-button-loading {
  position: absolute;
  width: 10px;
  height: 10px;
  fill: var(--trade-color-operate);
}

.x-loading-txt {
  position: absolute;
}
