@keyframes NudexLoading {
  from {
    transform: rotate3d(0, 0, 1, 0);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.nudexLoading {
  fill: var(--trade-color-operate);
  animation: NudexLoading 400ms linear infinite;
  width: 32px;
  height: 32px;
}

.loadingMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 90%,
    rgba(0, 0, 0, 0.3) 100%
  );
}
