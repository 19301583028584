#Ip-Limit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #141414;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .bg-ip {
    // filter: brightness(50%) contrast(150%);
    mix-blend-mode: hard-light;
  }
}
